export default class ThematicInterface {
  constructor(thematic, project, type) {
    switch (project) {
      case "bordeaux-tbm":
        const cat = `${type}`;

        this.cat = cat;
        this.cat_id = `poi_type:${cat}`;
        this.subcat = "empty-subcat";
        this.name = thematic.name;
        this.label = thematic.name;
        this.id = thematic.id;
        this.divIcon = true;
        this.coord = {
          lat: thematic.latitude,
          lon: thematic.longitude,
        };

        if (["vcub"].includes(type)) {
          let totalBikes = thematic.nbBikeAvailable + thematic.nbElectricBikeAvailable;
          let totalPlaces = thematic.nbPlaceAvailable;
          const display = thematic.display;
          const predict = thematic.predict;
          let color =
            parseInt(display === "bikes" ? totalBikes : totalPlaces) >= 3
              ? predict
                ? "blue"
                : "green"
              : parseInt(display === "bikes" ? totalBikes : totalPlaces) > 0
              ? "orange"
              : "red";

          if (thematic.connexionState === "MAINTENANCE") {
            color = "maintenance";
            totalBikes = null;
          }

          if (thematic.connexionState === "DECONNECTEE") {
            color = "unavailable";
            totalBikes = null;
          }

          const vcubPlus = thematic.typeVlsPlus && (display === "bikes") === "VLS_PLUS" ? "+" : "";

          this.code = `${cat}${vcubPlus}_${display !== "bikes" ? "slots_" : ""}${color}`;

          this.stand = {
            available_bikes: thematic.nbBikeAvailable,
            available_electric_bikes: thematic.nbElectricBikeAvailable,
            available_total_bikes: totalBikes,
            available_places: totalPlaces,
          };

          if (predict) {
            this.stand["time"] = predict.tau;
            this.stand["available_bikes"] = predict.bikes;
            this.stand["available_total_bikes"] = predict.bikes;
            this.stand["available_places"] = predict.free_slots;
            totalBikes = predict.bikes;
            totalPlaces = predict.free_slots;
          }

          this.color = color;
          this.value = display === "bikes" ? totalBikes : totalPlaces;
        } else if (type === "p+r") {
          const predict = thematic.predict;
          let totalPlaces = thematic.freeSpots !== null && thematic.freeSpots !== undefined ? thematic.freeSpots : 0;
          let totalElecCar = thematic.vlecSpace > 0 ? thematic.freeVlec : null;
          let totalPmr = thematic.pmrSpace > 0 ? thematic.freePmr : null;
          let isReducedPrice = thematic.isReducedPrice;
          // Parks predict specification for 4 weeks
          const predictFilter = [7, 19, 8];
          let color =
            thematic.parkState === "F"
              ? "closed"
              : totalPlaces === 0
              ? "unavailable"
              : parseInt(totalPlaces) >= 20
              ? "green"
              : parseInt(totalPlaces) < 20 && parseInt(totalPlaces) >= 10
              ? "orange"
              : "red";

          if (predict && color === "green") {
            color = "blue";
          }

          this.code = `${cat}_slots_${color}${isReducedPrice ? "_reduced" : ""}`;

          this.stand = {
            available: totalPlaces,
            available_electric_cars: totalElecCar,
            available_prm: totalPmr,
          };
          this.capacity = thematic.vlSpace;

          if (predict) {
            const tau = thematic.tau || 15;
            const data = predict.predictions.find((p) => p.tau_in_min === tau);

            if (data) {
              this.stand["time"] = data.tau_in_min;
              this.stand["available"] = data.free_spots;
              totalPlaces = data.free_spots;
            }
          }

          this.id = thematic.parkingId || thematic.id;
          this.color = color;
          this.value = totalPlaces;
          this.updatedAt = thematic.updatedAt;
          this.info = thematic.address + " " + thematic.postalCode + " " + thematic.city;
          this.isReducedPrice = isReducedPrice;
          this.predictFilter = predictFilter.includes(thematic.parkingId);
        }

        break;
      default:
        break;
    }
  }
}

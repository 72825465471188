import Plausible from "plausible-tracker";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { envVarToBool, debug } from "./services/tools";
import { appStore } from "./store";

const { REACT_APP_GTM, REACT_APP_PLAUSIBLE, REACT_APP_GTM_LIB, REACT_APP_GA } = process.env;
let plausible = null;

export const initGTM = (configApp) => {
  if (REACT_APP_GTM || configApp.gtm_key) {
    const tagManagerArgs = {
      gtmId: configApp.gtm_key,
    };

    TagManager.initialize(tagManagerArgs);
  }
};

export const initGA = (configApp) => {
  if (REACT_APP_GA || configApp.ga_key) {
    ReactGA.initialize(configApp.ga_key || REACT_APP_GA);
  }
};

export const initPlausible = () => {
  if (REACT_APP_PLAUSIBLE) {
    plausible = Plausible({
      domain: REACT_APP_PLAUSIBLE,
      apiHost: "https://analytics.lc.tools",
      hashMode: true,
    });
  }
};

export const UpdatePageView = () => {
  const hasAuthorizedCookies = appStore.getState().app.hasAuthorizedCookies;

  if (plausible) {
    plausible.trackPageview();
  }

  if ((REACT_APP_GA || appStore.getState().app.configApp?.google_analytics) && hasAuthorizedCookies) {
    ReactGA.pageview(window.location.href);
  }
};

export const updateDataLayer = (dataLayer) => {
  const hasAuthorizedCookies = appStore.getState().app.hasAuthorizedCookies;
  const configApp = appStore.getState().app.configApp;

  debug({ message: `Update datalayer :`, data: dataLayer }, "info", "DataLayer");

  if ((REACT_APP_GTM || configApp.gtm_key) && hasAuthorizedCookies) {
    const tagManagerArgs = {
      dataLayer,
    };

    TagManager.dataLayer(tagManagerArgs);
  } else if (envVarToBool(REACT_APP_GTM_LIB)) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
  }

  if ((REACT_APP_GA || appStore.getState().app.configApp?.google_analytics) && hasAuthorizedCookies) {
    ReactGA.event({ category: "lc-map", action: dataLayer.event });
  }
};
